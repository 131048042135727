.gm-style {
    .gm-style-iw-t {
        &::after {
            content: initial;
        }
    }

    .gm-style-iw-c {
        padding: 0 !important;
        box-shadow: 0 2px 8px 0 rgba(black, 0.16);
        border-radius: 2px;
    }

    .gm-style-iw-ch {
        display: none !important;
    }

    .gm-ui-hover-effect {
        position: absolute !important;
        top: 2px !important;
        right: 2px !important;
        width: 32px !important;
        height: 32px !important;
        opacity: 1 !important;
        background-color: white !important;
        z-index: 10;

        span {
            background-color: black;
            width: 22px !important;
            height: 22px !important;
            margin: 5px !important;
        }
    }

    .gm-style-iw-d {
        overflow: hidden !important;

        & + button {
            display: none !important;
        }
    }

    .gm-style-iw-tc {
        top: -1px !important;
    }
}
