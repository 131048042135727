// @import 'swiper/swiper.scss';
// @import 'swiper/components/a11y/a11y.scss';
// @import 'swiper/components/scrollbar/scrollbar.scss';
// @import 'react-bnb-gallery/dist/style';

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    @extend %body-copy;

    &:after {
        content: '';
        display: block;
        position: fixed;
        width: 0;
        height: 0;
        background-color: transparent;
        top: 0;
        left: 0;
        z-index: $z-dimmed;

        transition: background-color 0.7s;
    }

    &.Dimmed {
        &:after {
            content: '';
            height: 100%;
            width: 100%;
            background-color: $colorBlack40;
        }
    }
}

button {
    cursor: pointer;
}

a:focus {
    outline-width: 2px;
    outline-style: solid;
}

/* https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    text-transform: none;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}

.richtext-image.full-width {
    width: 100%;
    height: auto;
    margin-bottom: -10px;
}

.rich-text--caption {
    margin-top: 4px;
}

.rich-text--caption,
.rich-text--credits {
    @extend %img-caption;
    line-height: 1;
    color: $colorBlack70;
}

img + .rich-text--image-meta {
    margin-top: 0px;
}

.rich-text--image-meta {
    margin-top: -20px;
    margin-bottom: 24px;
    line-height: 1;
    position: relative;
    background-color: #fff;
}
