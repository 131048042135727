@import 'variables';
@import 'mixins';

#cookiescript_injected,
#cookiescript_injected_fsd {
    background-color: white !important;

    #cookiescript_wrapper {
        width: calc(100vw - 32px);
        max-width: 610px;
        padding: 16px;
        max-height: 90vh;
        overflow-x: hidden;
        font-family: $fontSwedenSans;
    }

    #cookiescript_header {
        font-size: 2rem;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.7);

        @include media(m) {
            font-size: 2.4rem;
        }
    }

    #cookiescript_description,
    #cookiescript_description a {
        font-family: $fontSwedenSans;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.7);
    }

    #cookiescript_description a:hover {
        color: black;
    }

    #cookiescript_checkbox {
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-direction: row;
        }
    }

    .cookiescript_checkbox_label {
        flex-wrap: nowrap;

        .cookiescript_checkbox_text {
            font-family: $fontSwedenSans;
            letter-spacing: 0.56px;
            font-size: 1.2rem;
            color: rgba(0, 0, 0, 0.7);
        }
    }

    .mdc-checkbox {
        flex: 0 0 22px !important;
        width: 22px !important;
        height: 22px !important;

        .mdc-checkbox {
            &__background {
                width: 22px;
                height: 22px;
                border-width: 1px;
                border-radius: 4px;

                &::before {
                    top: -12px !important;
                    left: -12px !important;
                    width: 44px !important;
                    height: 44px !important;
                }

                svg {
                    display: none;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    top: 1px;
                    left: 1px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: url('#{$basepath}svg/icon-checkmark.svg');
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }
            }

            &__native-control {
                width: 44px !important;
                height: 44px !important;

                &:enabled:checked,
                &:enabled:indeterminate {
                    ~ .mdc-checkbox__background {
                        border-color: $colorYellow;
                        background-color: $colorYellow;

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:disabled:checked {
                    ~ .mdc-checkbox__background {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    #cookiescript_buttons {
        #cookiescript_accept,
        #cookiescript_reject,
        #cookiescript_save {
            font-family: $fontSwedenSans;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.7);
            cursor: pointer;
            background-color: $colorYellow;
            border: none;
            padding: 15px 18px 13px;
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: 0.56px;
            line-height: 1;
            text-transform: uppercase;
            flex-grow: 1;

            @include media(m) {
                flex-grow: 0;
            }
        }

        #cookiescript_reject {
            color: rgba(0, 0, 0, 0.7);
            background-color: rgba(0, 0, 0, 0.1);

            &:hover {
                background-color: rgba(0, 0, 0, 0.3);

                @media (hover: none) {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &:active {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }

    #cookiescript_manage {
        span {
            font-family: $fontSwedenSans;
            letter-spacing: 0.56px;
        }
    }

    #cookiescript_manage {
        cursor: pointer;

        span {
            border-bottom: 1px solid transparent;
            transition: border-bottom-color 0.2s ease-in-out;
        }

        &:hover {
            #cookiescript_manageicon .cookiescript_gear {
                fill: rgba(0, 0, 0, 0.7);
            }

            span {
                border-color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    #cookiescript_maintabs {
        background: transparent;
        border: none;

        > div {
            color: rgba(0, 0, 0, 0.7);
            letter-spacing: 0.56px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom-width: 0;
            background: rgba(0, 0, 0, 0.1);

            &.cookiescript_active {
                color: rgba(0, 0, 0, 0.7);
                border-color: rgba(0, 0, 0, 0.1);
                background: white;
            }
        }
    }

    #cookiescript_reportdate {
        display: none !important;
    }
}

#cookiescript_categories,
#cookiescript_iab_type {
    > .cookiescript_active {
        background-color: $colorYellow !important;
        color: rgba(0, 0, 0, 0.7) !important;
    }
}

#cookiescript_badge {
    .cookiescriptlogo {
        fill: rgba(0, 0, 0, 0.7);
    }

    .cookiescriptlogo {
        transition-duration: 500ms;
    }

    #cookiescript_badgetext {
        font-family: $fontSwedenSans;
        color: rgba(0, 0, 0, 0.7);
        text-transform: none;
        font-size: 1.4rem;
        line-height: 2.8;
    }
}

#cookiescript_copyright {
    display: none;
}
