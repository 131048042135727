// Headroom
// Used for the sticky header, overriding Headroom styling
.headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    .NavSearching & {
        z-index: 1002;
    }
}

.Headroom--Sticky {
    height: 64px;
}

.headroom--unfixed {
    position: relative;
    transform: translateY(0);

    .NavigationMobile--Open {
        position: absolute;
        height: 100vh;
    }
}
.headroom--scrolled {
    transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);

    .Navigation__LanguageMenu--Open {
        display: none;
    }

    .Headroom--Sticky & {
        height: 100%;
        transform: translateY(0%);
    }
}
.headroom--pinned {
    position: fixed;
    transform: translateY(0%);

    .Headroom--Sticky & {
        height: 100%;
    }
}
